import React from 'react';
import { graphql } from 'gatsby';
import '../styles/category.scss';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import ButtonCircle from '../components/ButtonCircle';

const CategoryPage = (props) => {
  const {
    pageContext: {
      nom,
      description,
    },
  } = props;
  let edges = [];
  if (props.data && props.data.allDatoCmsChantier && props.data.allDatoCmsChantier.edges) {
    edges = props.data.allDatoCmsChantier.edges;
  }
  // const { data: { allDatoCmsChantier: { edges = [] } = {} } = {} } = props || {};
  const projectsElements = edges.map(project => (
    <div key={project.node.id} className="project_preview">
      <Img fluid={project.node.photos[0].fluid} />
      <div className="img_hover">
        <div
          onMouseEnter={(event) => { event.currentTarget.parentNode.parentNode.classList.add('hover'); }}
          onMouseLeave={(event) => { event.currentTarget.parentNode.parentNode.classList.remove('hover'); }}
        >
          <ButtonCircle link={project.node.slug} type="plus" />
        </div>
        <h3>{project.node.nom}</h3>
        <span>{project.node.anneeDeRealisation}</span>
      </div>
      <div className="hover_filter" />
    </div>
  ));
  return (
    <Layout menu={false}>
      <Helmet
        title={`${nom} - Ruiz Aluminium`}
      />
      <div className="category_container">
        <div className="category_return_button">
          <ButtonCircle link="#" type="arrow_left" />
        </div>
        <div className="category_content">
          {projectsElements[0]}
          <div className="category_infos">
            <h1>
              {nom.split(' ')[0]}
              <br />
              <strong>{nom.split(' ')[1]}</strong>
            </h1>
            <p>{description}</p>
          </div>
          {projectsElements.slice(1)}
        </div>
      </div>
    </Layout>
  );
};

CategoryPage.defaultProps = {
  data: {},
};

CategoryPage.propTypes = {
  pageContext: PropTypes.shape({
    nom: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allDatoCmsChantier: PropTypes
      .shape({ edges: PropTypes.array })
      .isRequired,
  }),
};

export default CategoryPage;

export const query = graphql`
  query Projects($categoryName: String!) {
    allDatoCmsChantier(filter: {categorie: {eq: $categoryName}}) {
      edges {
        node {
            id,
            nom,
            anneeDeRealisation,
            slug,
            photos {
              id,
              width,
              height,
              fluid(maxHeight: 500, imgixParams: { fm: "jpg", auto: "compress" }){
                ...GatsbyDatoCmsFluid
              },
            }
          }
        }
      }
  }
`;
